<script setup>
const props = defineProps({
  options: { // Needs to be an array of objects with {value: '', label: '', checked: false}
    type: Array,
    required: true
  },
  multiSelectLabel: {
    type: String,
    required: true
  },
  inputName: {
    type: String,
    required: true
  },
  enableAllRule: {
    type: Boolean,
    default: false
  },
  selected: {
    type: Array,
    default: ['All']
  },
})


// Handlers for values and labels shown on input
let inputValue = ref(''), inputText = ref(''), showDropdownOptions = ref(false)

function showDropdown(e) {
  showDropdownOptions.value = true
}
function hideDropdown(e) {
  showDropdownOptions.value = false
}
function checkboxSelected(element = null) {
  let checkboxes = document.querySelectorAll(`#multi-${props.inputName} .multi-select-dropdown .checkbox-container .form-check-input`)
  inputValue.value = ''
  inputText.value = ''

  if (props.enableAllRule && ((element !== null && element.value === 'All' && element.checked) || (element.target && element.target.value === 'All')) ) {
    inputValue.value = 'All'
    inputText.value = 'All'
    checkboxes.forEach((el, index) => {
      if (el.value !== 'All') {
        el.checked = false;
      }
      else {
        el.checked = true;
      }
    })
  }
  else {
    checkboxes.forEach((el, index) => {
      if (el.checked) {
        if (el.value === 'All') {
          el.checked = false
        }
        else {
          inputValue.value = inputValue.value == '' ? el.value : inputValue.value + ', ' + el.value
          inputText.value = inputText.value == '' ? el.getAttribute('label') : inputText.value + ', ' + el.getAttribute('label')
        }
      }
    })
  }

  // Checking 'All' in case all options are deselected
  if (props.enableAllRule && inputValue.value === '') {
    checkboxes.forEach((el, index) => {
      if (el.value !== 'All') {
        el.checked = false;
      }
      else {
        el.checked = true;
      }
    })
    inputValue.value = 'All'
    inputText.value = 'All'
  }
  
  
  // Once the checkboxes container is open we will keep it liek that until user click outside or the element loose focus
  showDropdownOptions.value = true;

  // Notifying the parent component of the updated value
  emit('onChanged', {
    id: props.inputName,
    value: inputValue.value.split(", "),
    valid: true
  })
}
function handleShowDropdown(e){
  if (showDropdownOptions.value) {
    hideDropdown(e)
  } else {
    showDropdown(e)
  }
}
const isLastItem = computed(() => {
  return (index) => index === props.options.length - 1
})
function handleFocusOutCheckbox(e, index) {  
  if (isLastItem.value(index)) {
    hideDropdown(e);
  }
}

onMounted(function (){
  document.body.addEventListener('click', function (e) {
    let fakeInput = e.target.id === `link-multi-${props.inputName}`
    let checkboxInput = e.target.classList.contains('form-checkbox')
    if (!fakeInput && !checkboxInput && showDropdownOptions.value){
      hideDropdown()
    }
  }, true);
  
  for (let i = 0; i < props.selected.length; i++) {
    checkboxSelected({value: props.selected[i], checked: true})
  }
  
  showDropdownOptions.value = false;
})

// The label class will be calculated to be able to put it into initial state (no values selected)
const labelClass = computed(() => {
  return {
    'floating-label': true,
    'active': true,
    'no-value': inputValue.value === ''
  }
})

const reset = () => {
  let checkboxes = document.querySelectorAll(`#multi-${props.inputName} .multi-select-dropdown .checkbox-container .form-check-input`)
  checkboxes.forEach((el, index) => {
    if (el.value === 'All') {
      el.checked = true
      inputValue.value = 'All'
      inputText.value = 'All'
    }
    else {
      el.checked = false
    }    
  })
}

defineExpose({
  reset
})

const emit = defineEmits(['onChanged'])
</script>
<template>
  <div class="form-floating input-container" :id="`multi-${inputName}`">
    <input type="hidden" :id="inputName" class="form-control form-input" :aria-label="inputName" :name="inputName" v-model="inputValue" :placeholder="multiSelectLabel">
    <label :class="labelClass" :for="inputName">{{ multiSelectLabel }}</label>
    <a :aria-label="multiSelectLabel" :class="'form-input fake-input form-select' + (showDropdownOptions ? ' active' : '')" @click.stop="handleShowDropdown" @keyup="showDropdown" tabindex="0" :id="`link-multi-${inputName}`">
      <span class="dropdown-content">{{ inputText ? inputText : "&nbsp;" }}</span>
      <!-- <span :class="'dropdown-button'"></span> -->
    </a>
    <div :class="'multi-select-dropdown' +  (showDropdownOptions ? ' active' : '')">
      <div class="checkbox-container" v-for="(items, index) in options">
        <div class="form-check" >
          <input  type="checkbox" @focusout="handleFocusOutCheckbox($event, index)" :id="`chb-${inputName}-${index}`" @click="checkboxSelected"  class="form-check-input" :value="items.value" :label="items.label" :index="index" :checked="items.checked"  />
          <label class="form-check-label" :for="`chb-${inputName}-${index}`">
            {{ items.label }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
// Multi Select Dropdown (uses checkbox styles)
.form-input {
  &.fake-input {
    text-decoration: none;
    color: $color-body-text;
    padding: 25px 33px 5px 13px;
    height: calc(3rem + 2px);

    .dropdown-button {
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: $color-body-text transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: transparent;
      pointer-events: none;
    }

    &.active,
    &:focus {
      box-shadow: none;
      border-color: #1F1F1F;
    }

    &.active {      
      .dropdown-button {
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent $color-body-text transparent;        
      }
    }

    .dropdown-content {
      position: relative;
      top: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      left: -3px;
    }
  }
}

// Forcing the label to take it's original position and style when there is no value selected
.form-floating {
  .floating-label {
    padding: 13px 10px;
    &.no-value {
      line-height: 1;
      transform: none !important;
      opacity: 1 !important;
    }
    &.active {
      top: 5px;
    }
  }
  
  .form-input.form-select {
    padding: 13px 13px 10px;
    height: 50px;
  }
}

.multi-select-dropdown {
  position: absolute;
  z-index: 100;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 5px;
  width: 100%;
  background-color: $color-secondary;
  border: solid 1px $color-body-text;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  .checkbox-container {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;

    &:first-child {
      padding-top: 10px;
    }

    .form-checkbox {
      width: 100%;
      height: 100%;
    }

    &:hover {
      background-color: $color-accent;
    }

    input.form-check-input ~ .form-check-label {
      padding-left: 5px;
      margin-bottom: 3px;
      width: 100%;
    }
  }
}</style>